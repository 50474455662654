/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        function offSetScroll(anchor) {
            var href = anchor.attr("href");
            offSetScrollFromLocation(href);
        }

        function offSetScrollFromLocation(href) {
            //Change this according to the height of the navigation bar
            var fromTop = 190;
            if(href.indexOf("#") === -1) {
                return;
            }
            var hash = href.substring(href.indexOf("#"));
            console.info(hash);
            var targetOffset = $(hash).offset().top-fromTop;
            $('html, body').animate({scrollTop: targetOffset}, 400, function(e) {});
        }

        $("a.in-page").click(function(event) {
            event.preventDefault();
            offSetScroll($(this));
        });
        offSetScrollFromLocation(window.location.href.toLowerCase());



        new UISearch( document.getElementById( 'sb-search' ) );

        $('.slider').slick({
            autoplay: true,
            autoplaySpeed: 6000,
            dots: false,
            fade: true,
            infinite: true,
            speed: 750
        });

        $('.gallery-block').matchHeight();
        $('.link-block').matchHeight();


        $.each($('.gallery-container'), function() {
            var galleryContainer = $(this);
            var arrowsContainer = $(this).find('.arrows-container');

            imagesLoaded(galleryContainer, function () {
                $('#' + galleryContainer.attr('id')).find('.gallery-slider').slick({
                    appendArrows: arrowsContainer,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    dots: false,
                    infinite: true,
                    speed: 750
                });
            });
        });

        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > 100) {
                $('.navbar-fixed-top').addClass('navbar-scrolled');
            } else {
                $('.navbar-fixed-top').removeClass('navbar-scrolled');
            }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'post_type_archive_partners': {
      init: function() {
        $('.sp-block article').matchHeight();
      }
    },
    'contact_us': {
      init: function() {
        $('.staff-block').matchHeight();
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
